// import React from "react";
// import Styles from "./Input.module.css";

// const Input = ({
//   id,
//   type,
//   placeholder,
//   htmlFor,
//   label,
//   textLabel,
//   required,
//   value,
//   onChange,
//   name,
//   error,
//   pattern,
//   checked,
//   index,
// }) => {
//   return type === "textarea" ? (
//     <div className={Styles.InputWrapper}>
//       {label ? (
//         <label htmlFor={htmlFor} className={Styles.Label}>
//           {textLabel}
//           {required ? <span className={Styles.Required}>*</span> : null}
//         </label>
//       ) : null}
//       <textarea
//         id={id}
//         name={name}
//         type={type}
//         rows={8}
//         cols={1}
//         style={{ height: "auto" }}
//         className={error ? `${Styles.Input} ${Styles.Error}` : Styles.Input}
//         placeholder={placeholder}
//         value={value}
//         onChange={onChange}
//         pattern={pattern}
//       />
//     </div>
//   ) : type === "checkbox" ? (
//     <div className={Styles.CheckboxWrapper} key={index}>
//       <input
//         id={`modal-checkbox-${value}`}
//         name={name}
//         value={value}
//         type={type}
//         className={Styles.Checkbox}
//         checked={checked}
//         onChange={onChange}
//       />
//       <label htmlFor={`modal-checkbox-${value}`}>{textLabel}</label>
//     </div>
//   ) : (
//     <div className={Styles.InputWrapper}>
//       {label ? (
//         <label htmlFor={htmlFor} className={Styles.Label}>
//           {textLabel}
//           {required ? <span className={Styles.Required}>*</span> : null}
//         </label>
//       ) : null}
//       <input
//         id={id}
//         name={name}
//         type={type}
//         className={error ? `${Styles.Input} ${Styles.Error}` : Styles.Input}
//         placeholder={placeholder}
//         value={value}
//         onChange={onChange}
//         pattern={pattern}
//       />
//     </div>
//   );
// };

// export default Input;

import React from "react";
import Styles from "./Input.module.css";

const Input = ({
  id,
  type,
  placeholder,
  htmlFor,
  label,
  textLabel,
  required,
  value,
  onChange,
  name,
  error,
  pattern,
  checked,
  index,
  options,
  optionsMultiSelection,
  multiSelectionModal,
  setMultiSelectionModal,
  modalRef,
}) => {
  return type === "textarea" ? (
    <div className={Styles.InputWrapper}>
      {label ? (
        <label htmlFor={htmlFor} className={Styles.Label}>
          {textLabel}
          {required ? <span className={Styles.Required}>*</span> : null}
        </label>
      ) : null}
      <textarea
        id={id}
        name={name}
        type={type}
        rows={8}
        cols={1}
        style={{ height: "auto" }}
        className={error ? `${Styles.Input} ${Styles.Error}` : Styles.Input}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        pattern={pattern}
      />
    </div>
  ) : type === "checkbox" ? (
    <div className={Styles.CheckboxWrapper} key={index}>
      <input
        id={`modal-checkbox-${value}`}
        name={name}
        value={value}
        type={type}
        className={Styles.Checkbox}
        checked={checked}
        onChange={onChange}
      />
      <label htmlFor={`modal-checkbox-${value}`}>{textLabel}</label>
    </div>
  ) : type === "select" ? (
    <div className={Styles.InputWrapper}>
      {label ? (
        <label htmlFor={htmlFor} className={Styles.Label}>
          {textLabel}
          {required ? <span className={Styles.Required}>*</span> : null}
        </label>
      ) : null}
      <select className={Styles.Input} value={value} onChange={onChange}>
        {options.map((item, i) => {
          return <option key={i}>{item.name}</option>;
        })}
      </select>
    </div>
  ) : type === "multiselect" ? (
    <div className={Styles.MultiselectWrapper}>
      <div className={Styles.InputWrapper}>
        {label ? (
          <label htmlFor={htmlFor} className={Styles.Label}>
            {textLabel}
            {required ? <span className={Styles.Required}>*</span> : null}
          </label>
        ) : null}
        <input
          className={Styles.Input}
          placeholder={placeholder}
          value={value}
          onClick={() => setMultiSelectionModal((prev) => !prev)}
          readOnly
        />
      </div>
      {multiSelectionModal ? (
        <div className={Styles.CheckboxModal} ref={modalRef}>
          {optionsMultiSelection.map((item, i) => {
            return (
              <div className={Styles.CheckboxWrapper} key={i}>
                <input
                  id={`modal-checkbox-${item.value}`}
                  name={item.name}
                  value={item.value}
                  type={"checkbox"}
                  className={Styles.Checkbox}
                  checked={item.checked}
                  onChange={onChange}
                />
                <label htmlFor={`modal-checkbox-${item.value}`}>
                  {item.name}
                </label>
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  ) : (
    <div className={Styles.InputWrapper}>
      {label ? (
        <label htmlFor={htmlFor} className={Styles.Label}>
          {textLabel}
          {required ? <span className={Styles.Required}>*</span> : null}
        </label>
      ) : null}
      <input
        id={id}
        name={name}
        type={type}
        className={error ? `${Styles.Input} ${Styles.Error}` : Styles.Input}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        pattern={pattern}
      />
    </div>
  );
};

export default Input;
