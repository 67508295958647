export const ruleFortyObj = {
  label: "Rule of Forty",
  key: "rule_of_forty",
  type: "dropdown",
  options: [
    {
      name: "All",
      tooltip: "",
      query: {
        operator: "",
        operands: ["rule_of_forty", ""],
      },
    },
    {
      name: ">40",
      tooltip: "Greater than 40%",
      query: {
        operator: "gt",
        operands: ["rule_of_forty", 40],
      },
    },
    {
      name: "<0",
      tooltip: "Less than 0%",
      query: {
        operator: "lt",
        operands: ["rule_of_forty", 0],
      },
    },
    {
      name: "0 - 40",
      tooltip: "0% to 40%",
      query: {
        operator: "btwn",
        operands: ["rule_of_forty", 0, 40],
      },
    },
  ],
};
export const marketcapObj = {
  label: "Market Cap",
  key: "marketcap",
  type: "dropdown",
  options: [
    {
      name: "Market Cap",
      tooltip: "",
      query: {
        operator: "",
        operands: ["marketcap", ""],
      },
    },

    {
      name: "<0.25B",
      tooltip: "Lesser than 0.25B",
      query: {
        operator: "lt",
        operands: ["marketcap", 250000000],
      },
    },
    {
      name: "0.25B to 1B",
      tooltip: "0.25B to 1B",
      query: {
        operator: "btwn",
        operands: ["marketcap", 250000000, 1000000000],
      },
    },
    {
      name: "1B to 10B",
      tooltip: "1B to 10B",
      query: {
        operator: "btwn",
        operands: ["marketcap", 1000000000, 10000000000],
      },
    },
    {
      name: "Greater than 10B",
      tooltip: "Greater than 10B",
      query: {
        operator: "gt",
        operands: ["marketcap", 10000000000],
      },
    },
  ],
};

export const columnItems = [
  { key: "sector", label: "Sector" },
  { key: "ebitda", label: "Ebitda" },
  { key: "revenue_growth", label: "Revenue Growth" },
  { key: "price_change_percentage", label: "Price Change" },
  { key: "exchange_short_name", label: "Exchange" },
  { key: "industry", label: "Industry" },
  { key: "open_price", label: "Stock Price" },
  { key: "beta", label: "Beta" },
  { key: "is_etf", label: "Etf" },
  { key: "theme", label: "Theme" },
  { key: "subindustry", label: "Subindustry" },
  { key: "revenue_growth", label: "Revenue Growth" },
  { key: "optionable", label: "Optionable" },
  { key: "net_profit_marging", label: "Net Profit" },
  { key: "eps", label: "Eps" },
  { key: "earning_announcement", label: "Earning Announcement" },
  { key: "shares_outstanding", label: "Shares Outstanding" },
  { key: "marketcap", label: "Market Cap" },
];
