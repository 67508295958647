import React, { useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
// import Login from "./Pages/Login/ui/Login.js";
import Dashboard from "./Pages/Dashboard/ui/Dashboard.js";
import FaqPage from "./Pages/FAQ/ui/Faq.js";
import ContactUs from "./Pages/ContactUs/ui/ContacUs.js";
import BlogsDashboard from "./Pages/Blog/BlogsDashboard/BlogsDashboard.js";
import BlogPage from "./Pages/Blog/BlogPage/BlogPage.js";
import AddBlogPost from "./Pages/Blog/AddBlogPost/AddBlogPost.js";
import EditBlogPost from "./Pages/Blog/EditBlogPost/EditBlogPost.js";
import Login from "./Pages/Login/Login.js";
import { jwtDecode } from "jwt-decode";
import { CircularProgress } from "@mui/material";

// import Cookies from "js-cookie";
// import { jwtDecode } from "jwt-decode";
// import moment from "moment";
const AppRoutes = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const checkAuth = () => {
      const token = localStorage.getItem("tokenLogin");
      if (token) {
        const decode = jwtDecode(token);
        if (decode.exp * 1000 < Date.now()) {
          setIsAuthenticated(false);
          localStorage.removeItem("tokenLogin");
          setLoading(false);
        } else {
          setIsAuthenticated(true);
          setLoading(false);
        }
      } else {
        setIsAuthenticated(false);
        setLoading(false);
      }
    };
    checkAuth();
  }, []);

  const RequireAuth = ({ children, redirectTo }) => {
    if (loading) {
      return <CircularProgress />;
    } else {
      return isAuthenticated ? children : <Navigate to={redirectTo} />;
    }
  };
  // const [isAuthenticated, setIsAuthenticated] = useState(true);
  // const [loading, setIsLoading] = useState(true);

  // const RequireAuth = ({ children, redirectTo }) => {
  //   if (loading) return <div></div>;
  //   return isAuthenticated ? children : <Navigate to={redirectTo} />;
  // };
  // const NotRequireAuth = ({ children, redirectTo }) => {
  //   if (loading) return <div></div>;
  //   return !isAuthenticated ? children : <Navigate to={redirectTo} />;
  // };

  // useEffect(() => {
  //   const autoLogout = () => {
  //     const token = Cookies.get("token");
  //     if (token) {
  //       const decodededToken = jwtDecode(token);
  //       if (moment.unix(decodededToken.exp).isBefore(moment())) {
  //         setIsAuthenticated(false);
  //         Cookies.remove("token");
  //       } else {
  //         setIsAuthenticated(true);
  //       }
  //     } else {
  //       setIsAuthenticated(false);
  //     }
  //     setIsLoading(false);
  //   };
  //   autoLogout();
  //   const intervalId = setInterval(autoLogout, 10000);

  //   return () => clearInterval(intervalId);
  // }, []);

  return (
    <BrowserRouter>
      <Routes>
        {/* <Route
          path="/login"
          element={
            <NotRequireAuth redirectTo={"/"}>
              <Login setIsAuthenticated={setIsAuthenticated} />
            </NotRequireAuth>
          }
        /> */}

        <Route index element={<Dashboard />} />
        <Route path="faq" element={<FaqPage />} />
        <Route path="contact-us" element={<ContactUs />} />
        <Route
          path="/blog"
          element={<BlogsDashboard isAuthenticated={isAuthenticated} />}
        />
        <Route path="/blog/:id" element={<BlogPage />} />
        <Route
          path="/blog/add"
          element={
            <RequireAuth redirectTo={"/login"}>
              <AddBlogPost />
            </RequireAuth>
          }
        />
        <Route
          path="/blog/edit/:id"
          element={
            <RequireAuth redirectTo={"/login"}>
              <EditBlogPost />
            </RequireAuth>
          }
        />
        <Route
          path="/login"
          element={
            <Login
              setIsAuthenticated={setIsAuthenticated}
              setLoading={setLoading}
              isAuthenticated={isAuthenticated}
            />
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
